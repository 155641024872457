.ui.inverted.header{
    color: @primaryLinkInverted;
}

.login-page-button {
    margin: 5%;
    text-align: center !important;
    & a {
      padding: 5px;
      cursor: pointer;
    }
    & a:hover {
      background-color: @primaryLinkHoverBackground;
      color: @primaryLink !important;
      text-decoration: none;
    }
  }

.text-color{
    color: @primaryLinkInverted;
}

@media (max-width: 767px) {
  #switch_left {
    order: 2;
  }
  #switch_right {
    order: 1;
  }
}

